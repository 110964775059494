<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        结算统计
      </template>
      <template #input>
        <a-select
          v-model="configIdList"
          mode="multiple"
          style="width: 300px"
          placeholder="请选择考试名称（可多选）"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            :value="item.configId"
            v-for="item in examlist"
            :key="'exam' + item.configId"
            >{{ item.name }}</a-select-option
          >
        </a-select>
        <a-range-picker
          @change="changeStartTime"
          style="width: 380px"
          format="YYYY-MM-DD HH:mm:ss"
        />
        <a-button
          type="primary"
          class="btn"
          @click='onSearch()'
        >搜索</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="(item,index)=>index"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <a @click="onExport('single', row)">通过名单导出</a>
          </div>
        </template>
      </a-table>
      <div class="num-box" v-if="totalData.length">
        <span class="num">通过人数（结算）合计：{{totalData[0].name}}人</span>
        <span class="num">通过人数合计：{{totalData[1].name}}人</span>
        <a-button
        type="link"
        @click="onExport('all')"
        >导出</a-button>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "考试名称",
    align: "center",
    dataIndex: "value",
  },
  {
    title: "通过人数",
    align: "center",
    dataIndex: "value2",
  },
  {
    title: "最终价",
    align: "center",
    dataIndex: "value3",
  },
  {
    title: "操作",
    align: "center",
    width: 140,
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import { fun_formatData } from '@/unit/fun.js'
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      totalData:[],
      configIdList:[],
      examlist:[],
      starttime:'',
      endtime:'',
    };
  },
  // 事件处理器
  methods: {
    moment,
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    onSearch() {
      this.getManageList();
    },
    getManageList() {
      let dataJson = {
        dateType: (this.starttime ? 9 : 0), // 0-全部,1-本日,2-本周,3-本月,4-指定年份,5-指定月份,6-本年，9-自定义起始结束日期
        configId: this.configIdList.toString(),
        time1: (this.starttime? this.starttime : undefined),
        time2: (this.endtime? this.endtime : undefined),
      };
      this.loading = true
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/procedure/getProcedure',
        params: {
          inString: JSON.stringify(dataJson),
          pName: 'rpt_getSettlementData'
        }
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          if(res.data){
            let resultArr = res.data.split("$$");
            let resultCode = resultArr[0].split("@@");
            let resultData = resultArr[1].split("@@");
            resultData.forEach((element,index) => {
              let str = resultCode + '$$' + element
              let res = fun_formatData(str)
              if(index == 0){
                this.tableData = res.data
              } else if (index == 1){
                this.totalData = res.data
              }
            });
          } else {
            this.tableData = []
            this.totalData = []
          }
        }
      })
    },
    // 获取考试列表
    getExamList(){
      this.$ajax({
        url:'/hxclass-management/exam/config/list',
        method:'get',
        params:{
          current:1,
          size:200,
        }
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.examlist = res.data.records;
        }else{
          this.$message.error(res.message);
        }
      })
    },
    changeStartTime(x, s) {
      this.starttime = s[0];
      this.endtime = s[1];
    },
    onExport(type, e){
      let url =  type == 'single' ? "/hxclass-management/settlement/selectExamDetailedList" : "/hxclass-management/settlement/selectExamList"
      window.open(
        this.$config.target +
          url + 
        "?configId=" +
        ((type == 'single') ? encodeURIComponent(e.name) : this.configIdList.toString()) + 
        "&stratDate=" +
        encodeURIComponent(this.starttime) +
        "&endDate=" +
        encodeURIComponent(this.endtime) + 
        "&payPrice=" +
        (e ? encodeURIComponent(e.value3) : '')
      );
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getExamList();
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}
.table-box {
  position: relative;
  .num-box{
    position: absolute;
    display: flex;
    align-items: center;
    left: 4px;
    bottom: 20px;
    font-size: 14px;
    .num{
      margin-right: 50px;
    }
  }
}
.option{
  display: flex;
  p{
    width: 100px;
    text-align: right;
  }
  p::before{
    content: "*";
    color: red;
  }
}
</style>
